import React from 'react';

import { ClassNameProps } from '@ComponentProps';

export interface ContentCardSeparatorProps extends ClassNameProps {}

export const ContentCardSeparator: React.FC<ContentCardSeparatorProps> = ({ className }) => (
  <div
    className={className}
    sx={{
      borderTopWidth: 'outlinedStrokeWeight',
      borderStyle: 'solid',
      borderColor: 'strokeLightneutral',
      marginTop: 'xl',
      marginBottom: 's',
    }}
  />
);
