import {
  Heading,
  Icon,
  Paragraph,
  Tooltip,
  Illustrations,
  type ColorsPalette,
  Icons,
  createResponsiveConfig,
  IncrementSizes,
  HeadingVariants,
  Responsive,
} from '@loveholidays/design-system';
import React, { ReactNode } from 'react';

import { ClassNameProps } from '@ComponentProps';
import { Illustration } from '@DesignSystemComponents/Illustration';

type TitleVariants = 'medium' | 'large';

type TitleAs = 'h1' | 'h2';

type Config = Record<
  TitleVariants,
  {
    titleVariant: Responsive<HeadingVariants>;
    iconSize: keyof IncrementSizes | (keyof IncrementSizes)[];
  }
>;

const titleConfig = createResponsiveConfig<Config>({
  medium: {
    titleVariant: 'mediumbold',
    iconSize: '40',
  },
  large: {
    titleVariant: ['mediumbold', 'largebold'],
    iconSize: ['40', '48'],
  },
});

export type ContentTitleProps = ClassNameProps & {
  headingTitle?: string;
  mainIconName?: Icons;
  mainIllustrationName?: Illustrations;
  mainIconColor?: keyof ColorsPalette;
  headingDescription?: string | JSX.Element;
  tooltipContent?: ReactNode;
  variant?: TitleVariants;
  as?: TitleAs;
};

export const ContentTitle: React.FC<ContentTitleProps> = ({
  className,
  mainIconName,
  mainIconColor,
  mainIllustrationName,
  headingTitle,
  headingDescription,
  tooltipContent,
  variant = 'large',
  as = 'h2',
}) => {
  const { titleVariant, iconSize } = titleConfig.get(variant);

  return (
    <div
      sx={{
        display: 'grid',
        gridTemplateColumns: tooltipContent ? 'auto 1fr auto' : 'auto 1fr',
        gap: 'xs',
        marginBottom: '2xl',
        alignItems: 'center',
      }}
      className={className}
    >
      {mainIconName && !mainIllustrationName && (
        <Icon
          name={mainIconName}
          size={iconSize}
          sx={{
            color: mainIconColor,
          }}
        />
      )}
      {mainIllustrationName && (
        <Illustration
          name={mainIllustrationName}
          size={iconSize}
        />
      )}
      {headingTitle && (
        <div>
          <Heading
            as={as}
            variant={titleVariant}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              marginBottom: '4xs',
            }}
          >
            {headingTitle}
          </Heading>
          {headingDescription && (
            <Paragraph
              variant="small"
              sx={{
                color: 'textDimmedheavy',
              }}
            >
              {headingDescription}
            </Paragraph>
          )}
        </div>
      )}
      {tooltipContent && (
        <Tooltip
          sx={{
            padding: '3xs',
          }}
          content={tooltipContent}
        >
          <Icon
            size="24"
            name="Markers/Tooltip"
          />
        </Tooltip>
      )}
    </div>
  );
};
