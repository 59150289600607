import { BasketHotel, LuggageDetails, LuggageProduct } from '@AuroraTypes';
import { getNumberOfChargeablePassengers } from '@Core/prices/getNumberOfPassengers';

// selectedBags will be <= 1 if perParty if true
// To show the number of bags correctly, we need to use the number of chargeable passengers
export const getNumberOfSelectedBags = (
  hotelProduct: BasketHotel | null | undefined,
  { selectedBags, perParty }: LuggageDetails | LuggageProduct,
): number => {
  let luggageQuantitySelected = selectedBags;

  if (perParty && hotelProduct) {
    const rooms = hotelProduct.rooms.map(({ partyConfiguration }) => partyConfiguration);

    luggageQuantitySelected = getNumberOfChargeablePassengers(rooms);
  }

  return luggageQuantitySelected;
};
