import { Label, Tooltip, Icon, Paragraph, Button } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { useCallback } from 'react';

import { LuggageOptions } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { StaticPrice } from '@Components/Price/StaticPrice';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import { getNumberOfSelectedBags } from '@Core/prices/getNumberOfSelectedBags';
import { usePriceQuoteUnit } from '@Core/prices/usePriceQuoteUnit';
import { useOrderStore } from '@Stores/checkout/CheckoutStoreContext';
import { useOrderSelectionStore } from '@Stores/checkout/CheckoutStoreContext';

interface LuggageOptionsControlsProps extends ClassNameProps {
  luggageOptions: LuggageOptions;
}

export const LuggageOptionsControls: React.FC<LuggageOptionsControlsProps> = ({
  className,
  luggageOptions,
}) => {
  const { t } = useTranslation();
  const { priceQuoteUnit } = usePriceQuoteUnit();
  const cabinBagDetailsInlineEnabled = useFeatureFlag('CabinBagDetailsInline');

  const [luggageSelection, setLuggageSelection] = useOrderSelectionStore((state) => [
    state.luggageSelection,
    state.setLuggageSelection,
  ]);

  const [shortRef, hotelProduct, marginStatus, luggageControlType] = useOrderStore((state) => [
    state.order.shortRef,
    state.getHotel(),
    state.marginStatus,
    state.getLuggageControlType(),
  ]);

  const { luggageDetails, mode } = luggageOptions;

  if (!luggageDetails) {
    // luggageDetails only null when mode NOT_AVAILABLE and we already check this in parent component
    return null;
  }

  const { id, weight, perParty, availableBags, price } = luggageDetails;

  const selectedBags = luggageSelection.selectedBags;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const updateLuggage = useCallback(
    async (newSelectedBags: number) => {
      setLuggageSelection({
        id,
        perParty,
        price,
        selectedBags: newSelectedBags,
        type: 'LUGGAGE',
        weight,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shortRef],
  );

  const luggagePriceTotal = price * selectedBags;

  let addLuggageDescription: string;
  if (selectedBags > 0) {
    const numberOfSelectedBags = perParty
      ? getNumberOfSelectedBags(hotelProduct, luggageDetails)
      : selectedBags;

    addLuggageDescription = t('checkout.addedBags', { count: numberOfSelectedBags, weight });
  } else if (perParty) {
    addLuggageDescription = t('checkout.addBaggageForAllPassengers', {
      weight,
      context: cabinBagDetailsInlineEnabled ? 'V2' : '',
    });
  } else if (cabinBagDetailsInlineEnabled && mode === 'NOT_AVAILABLE') {
    addLuggageDescription = t('checkout.notIncludedBaggageDescription', {
      context: 'V2',
    });
  } else {
    addLuggageDescription = t('checkout.addBaggageUpTo', {
      quantityOffered: availableBags,
      weight,
      context: cabinBagDetailsInlineEnabled ? 'V2' : '',
    });
  }

  const perPersonPricing = priceQuoteUnit === 'PER_PERSON';
  // This divisor is to initially display the price of a luggage per way if per person is toggled
  const divisor = perPersonPricing ? 2 : 1;
  const upfrontPriceIllustration = price / divisor;

  let fromPriceDescription: string = '';
  if (perPersonPricing) {
    if (perParty) {
      fromPriceDescription = t('eachWay');
    } else {
      fromPriceDescription = t('checkout.perBagEachWay');
    }
  }

  return (
    <div
      className={className}
      sx={{
        display: 'grid',
        gridTemplateRows: ['auto 1fr', null, '1fr'],
        gridTemplateColumns: ['1fr', null, '1fr auto'],
        gap: ['s', 'xs', 's'],
        paddingTop: [0, '3xs'],
        alignItems: ['flex-end', null, 'center'],
      }}
    >
      <div sx={{ display: 'flex', flexDirection: 'column' }}>
        {cabinBagDetailsInlineEnabled && (
          <Label
            variant="mediumbold"
            sx={{ marginBottom: '3xs' }}
          >
            {t('checkout.addBaggageTitle')}
          </Label>
        )}

        <Label
          variant="medium"
          sx={{ display: 'flex' }}
        >
          <span
            sx={{
              color: 'textDimmedheavy',
            }}
          >
            {addLuggageDescription}
          </span>

          {luggageControlType === 'ALL' && !cabinBagDetailsInlineEnabled && (
            <Tooltip
              sx={{
                paddingLeft: '3xs',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
              content={
                <Paragraph variant="small">{t('checkout.addBaggageWholePartyTooltip')}</Paragraph>
              }
            >
              <Icon
                size="16"
                name="Markers/Tooltip"
              />
            </Tooltip>
          )}
        </Label>
      </div>
      {mode !== 'NOT_AVAILABLE' && (
        <div
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: ['space-between', null, 'flex-end'],
          }}
        >
          <div
            sx={{
              marginRight: 'l',
              display: 'flex',
              alignItems: 'center',
              gap: '4xs',
              flexWrap: 'wrap',
            }}
          >
            <div
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '4xs',
              }}
            >
              {selectedBags === 0 && <Label variant="medium">{t('priceQuoteUnit.from')}</Label>}
              <Label variant={['largebold', 'extralargebold']}>
                <StaticPrice
                  value={selectedBags === 0 ? upfrontPriceIllustration : luggagePriceTotal}
                  decimal
                />
              </Label>
            </div>
            <Label variant="medium">
              {selectedBags === 0
                ? fromPriceDescription
                : t('checkout.bagsTotalDisplay').toLowerCase()}
            </Label>
          </div>
          {luggageControlType === 'INDIVIDUAL' && (
            <div
              sx={{
                display: 'flex',
                gap: '3xs',
                alignItems: 'center',
              }}
            >
              <Button
                data-id="control-down"
                variant="PrimaryDefault"
                size="36"
                icon="Actions/AdjustRemove"
                onClick={() => updateLuggage(selectedBags - 1)}
                disabled={selectedBags <= 0}
              />
              <Label
                variant="medium"
                data-id="control-quantity"
                sx={{ marginX: '2xs' }}
              >
                {selectedBags}
              </Label>
              <Button
                data-id="control-up"
                variant="PrimaryDefault"
                size="36"
                icon="Actions/AdjustAdd"
                onClick={() => updateLuggage(selectedBags + 1)}
                disabled={selectedBags >= availableBags || marginStatus === 'refreshing'}
              />
            </div>
          )}
          {luggageControlType === 'ALL' && (
            <Button
              data-id="control-toggle"
              variant="PrimaryDefault"
              size="48"
              icon={selectedBags > 0 ? 'Actions/AdjustRemoveCircle' : 'Actions/AdjustAddCircle'}
              onClick={() => (selectedBags > 0 ? updateLuggage(0) : updateLuggage(availableBags))}
            >
              {selectedBags > 0 ? t('checkout.removeBags') : t('checkout.addBags')}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
