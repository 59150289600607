import { createResponsiveConfig } from '@loveholidays/design-system';
import { SystemStyleObject } from '@styled-system/css';
import React, { forwardRef, Fragment, PropsWithChildren, ReactNode } from 'react';

import { ContentCardSeparator } from './ContentCardSeparator';
import { ClassNameProps } from '@ComponentProps';

type CardVariants = 'full' | 'bordered';
type Config = Record<CardVariants, SystemStyleObject>;

const cardConfig = createResponsiveConfig<Config>({
  full: {
    marginX: ['-xs', 0],
    borderRadius: [null, '12'],
    borderLeftStyle: ['none', 'solid'],
    borderRightStyle: ['none', 'solid'],
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
  },
  bordered: {
    marginX: 0,
    borderRadius: '12',
    borderStyle: 'solid',
  },
});

export interface ContentCardProps extends ClassNameProps {
  titleSection?: ReactNode;
  childStyling?: SystemStyleObject;
  variant?: CardVariants;
}

export const ContentCard = forwardRef<HTMLDivElement, PropsWithChildren<ContentCardProps>>(
  (
    { className, 'data-id': dataId, children, titleSection, childStyling, variant = 'full' },
    ref,
  ) => (
    <div
      ref={ref}
      className={className}
      data-id={dataId}
      sx={{
        ...cardConfig.get(variant),
        borderColor: 'strokeLightneutral',
        borderWidth: 'activeStrokeWeight',
        backgroundColor: 'white',
        marginBottom: 'l',
        paddingY: ['s', 'xl'],
      }}
    >
      {titleSection && (
        <Fragment>
          <div
            sx={{
              paddingX: ['xs', '2xl'],
            }}
          >
            {titleSection}
          </div>
          <ContentCardSeparator />
        </Fragment>
      )}
      <div
        sx={{
          ...childStyling,
          paddingX: ['xs', '2xl'],
        }}
      >
        {children}
      </div>
    </div>
  ),
);
