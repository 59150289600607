import { Label, Paragraph } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { LuggageOptions } from '@AuroraTypes';
import { captureClientError } from '@Core/errors/errors';
import { useOrderStore } from '@Stores/checkout/CheckoutStoreContext';

export const NO_LIMIT_CABIN_WEIGHT = 'NO_LIMIT';
export const NO_DIMENSION = 'NO_DIMENSION';

export const LuggageDescription: React.FC<{
  luggageOptions: LuggageOptions;
}> = ({ luggageOptions }) => {
  const { cabinBagsDetails, mode, luggageDetails } = luggageOptions;

  const flight = useOrderStore((state) => state.getFlight());

  const { t } = useTranslation();
  let luggageDescription: JSX.Element[] = [];

  const { availableBags, weight } = luggageDetails ?? {};

  let includedDescriptionLabel =
    mode === 'INCLUDED' && t('checkout.freeBaggageDescription', { availableBags, weight });

  if (!cabinBagsDetails) {
    const inboundCarriers = flight?.inbound
      .map(({ airline, operatingCarrier }) => `${airline.name} (${operatingCarrier})`)
      .join(' -> ');

    const outboundCarriers = flight?.outbound
      .map(({ airline, operatingCarrier }) => `${airline.name} (${operatingCarrier})`)
      .join(' -> ');

    captureClientError(
      `Missing cabin bags details for inbound flights: ${inboundCarriers} and outbound flights: ${outboundCarriers}`,
    );
  } else {
    const {
      underseatBagIsIncluded,
      underseatBagWeight,
      underseatBagDimensions,
      largeCabinBagIsIncluded,
      largeCabinBagAllowance,
      largeCabinBagWeight,
      largeCabinBagDimensions,
    } = cabinBagsDetails;

    const cabinLuggage = [
      {
        weight: underseatBagWeight,
        dimension: underseatBagDimensions,
        isIncluded: underseatBagIsIncluded,
        allowance: 1,
        descriptionKey: 'checkout.underseatBagsDescription',
      },
      {
        weight: largeCabinBagWeight,
        dimension: largeCabinBagDimensions,
        isIncluded: largeCabinBagIsIncluded,
        allowance: largeCabinBagAllowance,
        descriptionKey: 'checkout.cabinBagsDescription',
      },
    ]
      .map(({ isIncluded, allowance, weight, dimension, descriptionKey }, index) => {
        let context = '';
        if (weight === NO_LIMIT_CABIN_WEIGHT) {
          context += NO_LIMIT_CABIN_WEIGHT;
        }
        if (!dimension) {
          context += context ? `_${NO_DIMENSION}` : NO_DIMENSION;
        }

        return (
          isIncluded &&
          weight && (
            <Paragraph
              key={index}
              variant="small"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                color: 'textDimmedheavy',
              }}
            >
              <span sx={{ paddingRight: '3xs' }}>
                {t(descriptionKey, {
                  count: allowance,
                  bagWeight: weight,
                  bagDimensions: dimension,
                  context,
                })}
              </span>
            </Paragraph>
          )
        );
      })
      .filter(Boolean);

    luggageDescription = luggageDescription.concat(cabinLuggage as any);
  }

  if (includedDescriptionLabel) {
    luggageDescription.push(
      <Paragraph
        variant="small"
        sx={{
          color: 'textDimmedheavy',
        }}
      >
        {includedDescriptionLabel}
      </Paragraph>,
    );
  }

  return (
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Label
        variant="mediumbold"
        sx={{
          marginBottom: '2xs',
        }}
      >
        {t('checkout.baggageTitle')}
      </Label>

      <ul
        sx={{
          listStyle: 'disc',
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: ['l', 'xl'],
        }}
      >
        {luggageDescription.map((description, index) => (
          <li key={index}>{description}</li>
        ))}
      </ul>
    </div>
  );
};
