import { SetState } from 'zustand';

import {
  AncillariesInput,
  AncillaryProduct,
  AncillaryType,
  BoardBasisOption,
  LuggageProduct,
  PassengerDetailsInput,
  PaymentPlan,
  RoomOption,
  FullOrderHotelRoom,
  PaymentMethod,
} from '@AuroraTypes';
import { createStore, Store } from '@Core/createStore';
import { removeFunctionMembers } from '@Core/utils';
import { isApplePaySupported } from '@Stores/checkout/utils';

type WithId<T> = T & { id: number };

export type PassengerDetailsFormData = {
  rooms: PassengerDetailsInput[][];
  ancillaries: AncillariesInput;
};

export type UserInteraction = { acknowledged: boolean | undefined; mandatory: boolean };

export type MarginSlider = {
  // pending state means agent has updated the price but hasn't click the submit button
  state: 'error' | 'pending' | 'loading' | 'ok';
  offlinePrice?: number;
  percentage?: number;
  errorCode?: string;
  inputPrice?: string;
  inputPercentage?: string;
};

export interface OrderSelectionStore {
  luggageSelection: WithId<LuggageProduct>;
  setLuggageSelection: (luggageSelection: WithId<LuggageProduct>) => void;

  ancillarySelection: Record<AncillaryType, AncillaryProduct>;
  setAncillarySelection: (type: AncillaryType, selection: AncillaryProduct) => void;
  getAncillarySelection: (type: AncillaryType) => AncillaryProduct | undefined;

  requiredUserInteractions: Record<string, UserInteraction>;
  unacknowledgedUserInteractions: string[];
  setUserInteractionAcknowledgement: (id: string, config: UserInteraction) => void;
  removeRequiredUserInteraction: (appendix: string) => void;

  roomsSelection: Pick<FullOrderHotelRoom, 'id' | 'description' | 'partyId'>[];
  setRoomsSelection: (
    selection: Pick<FullOrderHotelRoom, 'id' | 'description' | 'partyId'>[],
  ) => void;
  setRoomSelectionForParty: (partyId: string, selection: RoomOption) => void;

  boardBasisSelection: BoardBasisOption | null;
  setBoardBasisSelection: (selection: BoardBasisOption) => void;

  passengerFormStatus: 'submitting' | 'done' | 'error';

  passengerDetails: PassengerDetailsFormData;
  setPassengerDetails: (passengerDetails: PassengerDetailsFormData) => void;
  passengerDetailsFormFieldValues: Record<string, any>;
  setPassengerDetailsFormFieldValue: (field: string, value: any) => void;

  voucherCode: string;
  setVoucherCode: (voucherCode: string) => void;

  paymentPlanSelection: PaymentPlan;
  paymentPlanSelectionDisplayedPrice: number;
  setPaymentPlanSelection: (paymentPlanSelection: PaymentPlan) => void;
  getPaymentMethods: () => PaymentMethod[];

  marginSlider: MarginSlider;
  setMarginSlider: (marginSlider: MarginSlider) => void;

  selectionsStatus: 'confirmed' | 'confirming' | 'error' | 'attentionRequired';
  confirmSelections: () => void;

  setInitialValues: (overrides?: Partial<OrderSelectionStore>) => void;
}

const reducer =
  <T>(set: SetState<OrderSelectionStore>, fieldName: keyof OrderSelectionStore) =>
  (value: T) =>
    set((state: OrderSelectionStore) => ({ ...state, [fieldName]: value }));

export const createOrderSelectionStore = (
  initialValues?: Partial<OrderSelectionStore>,
): Store<OrderSelectionStore> =>
  createStore<OrderSelectionStore>(
    (set, get) => ({
      luggageSelection: {
        id: 0,
        type: '',
        price: 0,
        perParty: false,
        selectedBags: 0,
        weight: 0,
      },
      setLuggageSelection: reducer<WithId<LuggageProduct>>(set, 'luggageSelection'),

      ancillarySelection: {} as Record<AncillaryType, AncillaryProduct>,
      setAncillarySelection: (type, selection) => {
        set((state) => ({
          ...state,
          ancillarySelection: {
            ...state.ancillarySelection,
            [type]: selection,
          },
        }));
      },
      getAncillarySelection: (ancillaryType: AncillaryType) => {
        const { ancillarySelection } = get();
        const ancillary = ancillarySelection[ancillaryType];

        if (!ancillary) {
          return;
        }

        return ancillary;
      },

      requiredUserInteractions: {},
      unacknowledgedUserInteractions: [],
      setUserInteractionAcknowledgement: (id, config) =>
        set((state) => ({
          ...state,
          requiredUserInteractions: {
            ...state.requiredUserInteractions,
            [id]: config,
          },
        })),
      removeRequiredUserInteraction: (id) =>
        set((state) => {
          const newState = { ...state };

          delete newState.requiredUserInteractions[id];

          return newState;
        }),

      roomsSelection: [],
      setRoomsSelection: reducer(set, 'roomsSelection'),
      setRoomSelectionForParty: (partyId, selection) => {
        const state = get();

        const roomsSelection = state.roomsSelection.map((room) =>
          room.partyId === partyId
            ? {
                ...selection,
                partyId,
              }
            : room,
        );

        set((state) => ({
          ...state,
          roomsSelection,
        }));
      },
      boardBasisSelection: null,
      setBoardBasisSelection: reducer(set, 'boardBasisSelection'),

      passengerFormStatus: 'done',
      passengerDetails: { rooms: [], ancillaries: {} },
      setPassengerDetails: (passengerDetails) =>
        set((state) => ({
          ...state,
          passengerDetails,
          passengerFormStatus: 'submitting',
        })),

      passengerDetailsFormFieldValues: {},
      setPassengerDetailsFormFieldValue: (field, value) =>
        set((state) => ({
          ...state,
          passengerDetailsFormFieldValues: {
            ...state.passengerDetailsFormFieldValues,
            [field]: value,
          },
        })),

      paymentPlanSelection: { id: '0', amount: 0, paymentMethods: [] },
      paymentPlanSelectionDisplayedPrice: 0,
      setPaymentPlanSelection: reducer(set, 'paymentPlanSelection'),

      getPaymentMethods: () => {
        const { paymentPlanSelection } = get();

        const paymentMethods = paymentPlanSelection.paymentMethods.filter((method) =>
          method === 'APPLE_PAY' ? isApplePaySupported() : true,
        );

        return paymentMethods as PaymentMethod[];
      },

      voucherCode: '',
      setVoucherCode: reducer(set, 'voucherCode'),

      marginSlider: {
        offlinePrice: undefined,
        percentage: undefined,
        errorCode: undefined,
        state: 'ok',
        inputPercentage: '',
        inputPrice: '',
      },
      setMarginSlider: (marginSlider: MarginSlider) =>
        set((state) => ({ ...state, marginSlider: { ...state.marginSlider, ...marginSlider } })),

      selectionsStatus: 'confirmed',
      confirmSelections: () => {
        set({ selectionsStatus: 'confirming' });
      },
      ...initialValues,
      setInitialValues: (overrides?: Partial<OrderSelectionStore>) => {
        const extended = createOrderSelectionStore(overrides).getState();
        set(removeFunctionMembers(extended) as OrderSelectionStore);
      },
    }),
    'OrderSelectionStore',
  );
